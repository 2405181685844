<template>
  <GridLayout
    v-on="$listeners"
    v-bind="gridProps"
    :layout="layout"
    :col-num="24"
    :row-height="0"
    :margin="[16, 16]"
    :use-css-transforms="false"
    :use-style-cursor="false"
    :is-draggable="!readonly"
    :is-resizable="!readonly"
    @layout-updated="onLayoutUpdated"
    @layout-ready="ready = true"
  >
    <GridItem
      v-for="item in layout"
      v-bind="item"
      :key="item.id"
      :min-w="2"
      :min-h="5"
      :widget="item"
      :widget-readonly="readonly"
      drag-allow-from=".widget-header"
    >
      <component :is="getPlayground(item.type)" :widget="item" />
    </GridItem>
  </GridLayout>
</template>

<script>
import debounce from "lodash/debounce";
import { GridLayout } from "vue-grid-layout";
import GridItem from "./GridItem";
import * as Widgets from "../widgets";

export default {
  components: { GridLayout, GridItem },
  props: {
    layout: Array,
    readonly: Boolean,
    gridProps: Object
  },
  model: {
    prop: "layout",
    event: "input"
  },
  data() {
    return { ready: false };
  },
  methods: {
    getPlayground: Widgets.getPlayground,
    onLayoutUpdated(data) {
      this.$emit("input", data);
      if (this.ready) {
        this.onChange(data);
      }
    },
    onChange: debounce(function(data) {
      this.$emit("change", data);
    }, 1000)
  }
};
</script>

<style lang="less" scoped>
@import "../vars.less";

.vue-grid-layout {
  /deep/ .vue-grid-placeholder {
    background: @widget-placeholder-background;
    border-radius: @widget-border-radius;
    opacity: 1;
  }
}
</style>
