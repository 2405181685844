export const type = "line-step";

export const name = "阶梯图";

export const description = "常用于展示数据变化趋势";

export const icon = `<svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M384 832H128a32 32 0 0 1 0-64h224v-96a32 32 0 0 1 32-32h224v-96a32 32 0 0 1 32-32h256a32 32 0 0 1 0 64h-224v96a32 32 0 0 1-32 32h-224v96a32 32 0 0 1-32 32z" fill="#29B983" p-id="17951"></path><path d="M384 512H128a32 32 0 0 1 0-64h224v-96a32 32 0 0 1 32-32h224V224a32 32 0 0 1 32-32h256a32 32 0 0 1 0 64h-224v96a32 32 0 0 1-32 32h-224v96a32 32 0 0 1-32 32z" fill="#706EE7" p-id="17952"></path></svg>`;

export { GroupLine as group } from "../groups";

export { dimensions } from "../bar-basic";

// eslint-disable-next-line prettier/prettier
export const Playground = () => import(/* webpackChunkName: "widget-playground" */ "./Playground");

// eslint-disable-next-line prettier/prettier
export const Decoration = () => import(/* webpackChunkName: "widget-decoration" */ "../line-basic/Decoration")

// eslint-disable-next-line prettier/prettier
export const Datasource = () => import(/* webpackChunkName: "widget-decoration" */ "../histogram-basic/Datasource")
