export const DimensionType = {
  Row: "row",
  Group: "group",
  Value: "value"
};

export class IWidgetColumn {
  columnId = null;
  columnName = null;
  columnType = null;
  columnIndex = 0;
  aggType = null;
  // 设置 name 属性便于直接将 column 对象传入 dataset 的 dimensions
  // 用 getter 计算会导致 echarts dataset 读取不到 name，所以不使用 getter 方式定义 name
  // 默认情况下，name 与 columnName 相同。在需要修改维度名称时，需更新 name 而非 columnName
  name = null;
  dimension = null;
  // 字段标记，辅助判断字段功能
  columnMeta = {};
  constructor(opts) {
    Object.assign(this, opts);
    this.name = this.columnName;
  }
  /**
   * echarts 在处理 dataset.dimensions 使用了 `zrender/src/core/util` 中的 clone 方法复制对象，该方法忽略了 getter 属性。
   * name 属性用 getter 计算会导致 echarts dataset 读取不到 name，所以不使用 getter 方式定义 name。
   * @date 2022-08-16
   */
  // get name() {
  //   return this.columnName;
  // }
  get isRow() {
    return this.dimension == DimensionType.Row;
  }
  get isGroup() {
    return this.dimension == DimensionType.Group;
  }
  get isValue() {
    return this.dimension == DimensionType.Value;
  }
  clone() {
    return new IWidgetColumn(this);
  }
  static from(opts) {
    return new IWidgetColumn(opts);
  }
}

export class IWidgetDatasource {
  datasheetId = null;
  /** @type {IWidgetColumn[]} */
  columns = [];
  filter = {};
  constructor(opts) {
    opts = opts || {};
    Object.assign(this, opts);
    this.columns = [].concat(opts.columns || []).map(IWidgetColumn.from);
    this.datasheetId = opts.datasheetId || null;
    Object.assign(this.filter, opts.filter);
  }
  static from(opts) {
    return new IWidgetDatasource(opts);
  }
}
