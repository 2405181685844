export const type = "text";

export const name = "文本组件";

export const description = "展示富文本";

export const icon = null;

// 组件默认尺寸
export const size = { w: 24, h: 10 };

// 自动调整组件高度
export const autosize = true;

// 自动收起组件 header
export const flexible = true;

// eslint-disable-next-line prettier/prettier
export const Playground = () => import(/* webpackChunkName: "widget-text" */ "./Playground");

// eslint-disable-next-line prettier/prettier
export const Assembly = () => import(/* webpackChunkName: "widget-texteditor" */ "./Assembly")
