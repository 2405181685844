export const type = "bar-basic";

export const name = "基础条形图";

export const description = "常用于数据对比";

export const icon = `<svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M497.777778 142.22222199l0 142.22222202-312.888889 0L184.888889 142.22222199z" fill="#29C287"></path><path d="M839.111111 341.333333l0 142.222223-654.222222 0 0-142.222223z" fill="#706EE7" p-id="12342"></path><path d="M611.555556 540.444444l0 142.222223-426.666667 0 0-142.222223z" fill="#29C287" p-id="12343"></path><path d="M412.444444 739.555556l0 142.222222-227.555555 1e-8 0-142.22222202z" fill="#706EE7"></path></svg>`;

export { GroupBar as group } from "../groups";

export const dimensions = "row: 1, value: 1+, group: 0-1";

// eslint-disable-next-line prettier/prettier
export const Playground = () => import(/* webpackChunkName: "widget-playground" */ "./Playground");

// eslint-disable-next-line prettier/prettier
export const Decoration = () => import(/* webpackChunkName: "widget-decoration" */ "./Decoration")

// eslint-disable-next-line prettier/prettier
export const Datasource = () => import(/* webpackChunkName: "widget-decoration" */ "./Datasource")
