export const GroupLine = 0;
export const GroupHistogram = 1;
export const GroupBar = 2;
export const GroupKpi = 3;
export const GroupPie = 4;
export const GroupMixed = 5;
export const FunnelDiagram = 6;
export const GroupTable = 7;

export const Groups = [
  {
    type: GroupLine,
    icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24"><path d="M5 3v16h16v2H3V3h2zm15.293 3.293l1.414 1.414L16 13.414l-3-2.999-4.293 4.292-1.414-1.414L13 7.586l3 2.999 4.293-4.292z"/></svg>`,
    label: "折线图"
  },
  {
    type: GroupHistogram,
    icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24"><path d="M3 12h2v9H3v-9zm16-4h2v13h-2V8zm-8-6h2v19h-2V2z"/></svg>`,
    label: "柱状图"
  },
  {
    type: GroupKpi,
    icon: `<svg fill="currentColor" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M89.7792 848.2944h161.152l-80.576-161.152-80.576 161.152z m225.6128-96.6912H960v96.6912H315.392v-96.6912z"></path><path d="M921.3184 293.9392c0-61.248-51.5584-112.8064-112.8064-112.8064-61.2352 0-112.8064 51.5584-112.8064 112.8064h64.4608c0-25.792 22.5664-48.3456 48.3456-48.3456 25.792 0 48.3456 22.5536 48.3456 48.3456 0 19.328-9.664 35.456-25.7792 41.8944-9.664 6.4512-19.328 16.1152-19.328 29.0048 0 12.8896 6.4384 22.5664 19.328 29.0048 16.1152 9.6768 25.7792 25.792 25.7792 41.9072 0 25.7792-22.5536 48.3456-48.3456 48.3456-25.7792 0-48.3456-22.5664-48.3456-48.3456h-64.4608c0 61.2352 51.584 112.8064 112.8064 112.8064 61.248 0 112.8064-51.584 112.8064-112.8064 0-25.792-9.664-51.584-25.7792-70.912 16.1152-19.328 25.7792-45.12 25.7792-70.912zM218.7008 181.12c-9.664-3.2256-22.5536-3.2256-32.2304 6.4384L64 284.2624l38.6816 48.3456 67.6736-54.784v270.72h64.4608V213.376c3.2256-12.9024-3.2128-25.792-16.1152-32.2304zM599.04 293.9392c0-29.0176-12.9024-58.0224-32.2304-80.576-22.5664-22.5664-48.3456-32.2304-80.576-32.2304-29.0176 0-58.0224 12.8896-80.576 32.2304-22.5664 19.328-32.2304 48.3456-32.2304 80.576h64.4608c0-12.9024 6.4384-25.792 12.8896-35.456 19.328-19.3408 48.3456-19.3408 67.6864 0 9.664 9.664 12.8896 22.5536 12.8896 35.456 0 12.8896-6.4512 25.7792-16.128 35.456L379.8656 496.9728c-6.4384 9.664-9.664 22.5536-3.2256 35.456 6.4512 12.8896 16.128 19.328 29.0176 19.328h193.3824v-64.4608h-125.696l93.4656-112.8064c19.328-22.5536 32.2304-51.5584 32.2304-80.5632z"></path></svg>`,
    label: "指标卡"
  },
  {
    type: GroupPie,
    icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24"><path d="M12 22C6.477 22 2 17.523 2 12c0-4.478 2.943-8.268 7-9.542v2.124A8.003 8.003 0 0 0 12 20a8.003 8.003 0 0 0 7.418-5h2.124c-1.274 4.057-5.064 7-9.542 7zm9.95-9H11V2.05c.329-.033.663-.05 1-.05 5.523 0 10 4.477 10 10 0 .337-.017.671-.05 1zM13 4.062V11h6.938A8.004 8.004 0 0 0 13 4.062z"/></svg>`,
    label: "饼图"
  },
  {
    type: GroupBar,
    icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24"><path d="M12 3v2H3V3h9zm4 16v2H3v-2h13zm6-8v2H3v-2h19z"/></svg>`,
    label: "条形图"
  },
  {
    type: GroupMixed,
    icon: `<svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="currentColor"><path d="M213.333333 853.333333a42.666667 42.666667 0 0 1-85.333333 0v-256a42.666667 42.666667 0 0 1 85.333333 0v256z m213.333334 0a42.666667 42.666667 0 0 1-85.333334 0v-384a42.666667 42.666667 0 0 1 85.333334 0v384z m213.333333 0a42.666667 42.666667 0 0 1-85.333333 0v-341.333333a42.666667 42.666667 0 0 1 85.333333 0v341.333333z m213.333333 0a42.666667 42.666667 0 0 1-85.333333 0V384a42.666667 42.666667 0 0 1 85.333333 0v469.333333zM197.333333 459.946667a42.666667 42.666667 0 1 1-53.333333-66.56l213.333333-170.666667a42.666667 42.666667 0 0 1 42.496-6.314667l192.512 76.970667 237.312-158.208a42.666667 42.666667 0 0 1 47.36 70.997333l-256 170.666667a42.666667 42.666667 0 0 1-39.509333 4.096l-190.293333-76.074667-193.877334 155.136z"></path></svg>`,
    label: "组合图"
  },
  {
    type: FunnelDiagram,
    icon: `<svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="currentColor"><path d="M374.528 500.053333 512 862.933333l136.832-362.88L832 222.933333 512 222.933333 192 222.933333 374.528 500.053333 374.528 500.053333zM512 731.52l-18.176-51.413333 32.853333 0L512 731.52 512 731.52zM541.141333 634.368l-58.496 0-39.637333-91.434667 137.6 0L541.141333 634.368 541.141333 634.368zM608.469333 478.250667l-8.192 18.986667L423.125333 497.237333l-8.234667-18.986667L367.189333 405.802667l289.194667 0L608.469333 478.250667 608.469333 478.250667zM746.965333 268.672 686.549333 360.106667 337.024 360.106667l-60.16-91.434667L512 268.672 746.965333 268.672 746.965333 268.672zM746.965333 268.672"></path></svg>`,
    label: "漏斗图"
  },
  {
    type: GroupTable,
    icon: `<svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"  fill="currentColor"><path d="M512 256a42.666667 42.666667 0 0 0-42.666667 42.666667v426.666666a42.666667 42.666667 0 0 0 85.333334 0V298.666667a42.666667 42.666667 0 0 0-42.666667-42.666667z m-213.333333 256a42.666667 42.666667 0 0 0-42.666667 42.666667v170.666666a42.666667 42.666667 0 0 0 85.333333 0v-170.666666a42.666667 42.666667 0 0 0-42.666666-42.666667z m426.666666-85.333333a42.666667 42.666667 0 0 0-42.666666 42.666666v256a42.666667 42.666667 0 0 0 85.333333 0v-256a42.666667 42.666667 0 0 0-42.666667-42.666666z m85.333334-341.333334H213.333333a128 128 0 0 0-128 128v597.333334a128 128 0 0 0 128 128h597.333334a128 128 0 0 0 128-128V213.333333a128 128 0 0 0-128-128z m42.666666 725.333334a42.666667 42.666667 0 0 1-42.666666 42.666666H213.333333a42.666667 42.666667 0 0 1-42.666666-42.666666V213.333333a42.666667 42.666667 0 0 1 42.666666-42.666666h597.333334a42.666667 42.666667 0 0 1 42.666666 42.666666z"></path></svg>`,
    label: "表格"
  }
];

export default Groups;
