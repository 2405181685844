import * as defs from "./defs";

export * from "./defs";

export { defs };

/**
 * 根据 widgetType 获取组件详情
 * @param {string} widgetType 组件类型
 * @returns {import("./type.d").IWidgetDescriber}
 */
export function getWidget(widgetType) {
  for (const widget of Object.values(defs)) {
    if (widget && widget.type === widgetType) {
      return widget;
    }
  }
  return null;
}

/**
 * 为异步组件添加 loading 效果
 * @param {string} widgetType 组件类型
 */
export function getPlayground(widgetType) {
  const widget = getWidget(widgetType);
  if (!widget) {
    return null;
  }
  if (widget._cachePlayground) {
    return widget._cachePlayground;
  }
  const Playground = widget.Playground;
  const component = typeof Playground == "function" ? Playground() : Playground;
  const loading = {
    render() {
      const style = {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      };
      return <a-spin style={style} />;
    }
  };
  const LazyLoading = () => ({ component, loading });
  widget._cachePlayground = LazyLoading;
  return LazyLoading;
}

window.getPlayground = getPlayground;

/**
 * @param {string} widgetType 组件类型
 */
export function getAssembly(widgetType) {
  const widget = getWidget(widgetType);
  return widget && widget.Assembly;
}

/**
 * @param {string} widgetType 组件类型
 */
export function getDecoration(widgetType) {
  const widget = getWidget(widgetType);
  return widget && widget.Decoration;
}

/**
 * @param {string} widgetType 组件类型
 */
export function getDatasource(widgetType) {
  const widget = getWidget(widgetType);
  return widget && widget.Datasource;
}

/**
 * @param {string} widgetType 组件类型
 */
export function isWidgetAutosize(widgetType) {
  const widget = getWidget(widgetType);
  return !!(widget && widget.autosize);
}

/**
 * @param {string} widgetType 组件类型
 */
export function isWidgetFlexible(widgetType) {
  const widget = getWidget(widgetType);
  return !!(widget && widget.flexible);
}
