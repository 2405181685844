export const type = "line-smooth";

export const name = "平滑折线图";

export const description = "常用于展示数据变化趋势";

export const icon = `<svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M710.39682178 790.95627705c-128.17802812 0-175.14877237-141.31204805-216.94972061-265.90671035C451.65149844 400.4527669 418.21458857 313.2781956 352.13612188 313.2781956c-111.85511133 0-159.2256709 256.3517795-159.22567003 358.25963028-0.00427588 21.9823251-17.82275625 39.80080547-39.80508222 39.80080547-21.98553222 0-39.8040126-17.8184795-39.80828848-39.80080547 0-86.77796396 42.59416289-437.8730001 238.83904073-437.8730001 128.17802812 0 175.14877237 141.3109793 216.94544472 265.90670948s75.23678847 211.77137197 141.31525518 211.77137196 111.45850401-123.79823964 119.41738154-167.18561806c4.28892099-21.98553222 25.58600333-36.33396328 47.57153554-32.04611104 21.9823251 4.28785224 36.32968653 25.58493369 32.04183516 47.56725967-2.78587442 9.55492998-48.56145117 231.27783926-199.03075225 231.27783926z m-1e-8 0" fill="#706EE7"></path></svg>`;

export { GroupLine as group } from "../groups";

export { dimensions } from "../bar-basic";

// eslint-disable-next-line prettier/prettier
export const Playground = () => import(/* webpackChunkName: "widget-playground" */ "./Playground");

// eslint-disable-next-line prettier/prettier
export const Decoration = () => import(/* webpackChunkName: "widget-decoration" */ "../line-basic/Decoration")

// eslint-disable-next-line prettier/prettier
export const Datasource = () => import(/* webpackChunkName: "widget-decoration" */ "../histogram-basic/Datasource")
