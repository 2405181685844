export const type = "funnel-basic";

export const name = "漏斗图";

export const description = "对整个流程有的变化过程有一个清晰认知";

export const icon = `<svg t="" class="icon" viewBox="0 0 1101 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2098"><path d="M240.9273349 420.64930563l49.85608177 184.09278228h498.57954552l49.85982734-184.09278228H240.9273349z m-1e-8 1e-8" fill="#FFC107" p-id="2099"></path><path d="M490.21523488 880.88032385H589.93114398l149.57573644-184.09278155H340.64324399l149.57199088 184.09278155z m-1e-8 0" fill="#29C287" p-id="2100"></path><path d="M938.93963503 144.51481523l-49.85982735 184.09184517H191.06750757L141.21142578 144.51481523h797.72820924z m-1e-8 0" fill="#706EE7" p-id="2101"></path></svg>`;

export { FunnelDiagram as group } from "../groups";

export const dimensions = "row: 1, value: 1+, group: 0";

// eslint-disable-next-line prettier/prettier
export const Playground = () =>
  import(/* webpackChunkName: "widget-playground" */ "./Playground");

// eslint-disable-next-line prettier/prettier
export const Decoration = () =>
  import(/* webpackChunkName: "widget-decoration" */ "./Decoration");

// eslint-disable-next-line prettier/prettier
export const Datasource = () =>
  import(/* webpackChunkName: "widget-decoration" */ "./Datasource");
