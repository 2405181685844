export const type = "histogram-basic";

export const name = "基础柱状图";

export const description = "常用于数据对比";

export const icon = `<svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M142.222 526.222h142.222v312.89H142.222z" fill="#29C287"></path><path d="M341.333 184.889h142.223V839.11H341.333z" fill="#706EE7" p-id="12173"></path><path d="M540.444 412.444h142.223v426.667H540.444z" fill="#29C287" p-id="12174"></path><path d="M739.556 611.556h142.222V839.11H739.556z" fill="#706EE7"></path></svg>`;

export { GroupHistogram as group } from "../groups";

export { dimensions } from "../bar-basic";

// eslint-disable-next-line prettier/prettier
export const Playground = () => import(/* webpackChunkName: "widget-playground" */ "./Playground");

// eslint-disable-next-line prettier/prettier
export const Decoration = () => import(/* webpackChunkName: "widget-decoration" */ "../bar-basic/Decoration")

// eslint-disable-next-line prettier/prettier
export const Datasource = () => import(/* webpackChunkName: "widget-decoration" */ "./Datasource")
