<template>
  <div
    ref="item"
    :class="[
      'vue-grid-item',
      classObj,
      { autosize: widget.autosize, readonly: widgetReadonly }
    ]"
    :style="style"
  >
    <div
      :class="[
        'widget-header',
        { flexible: widget.flexible, readonly: widgetReadonly }
      ]"
    >
      <div class="widget-title">
        <i class="ndl-icon-move-drag"></i>
        {{ widget.title || "未命名组件" }}
      </div>
      <div v-if="!widgetReadonly" class="widget-actions">
        <IconButton icon="ndl-icon-settings" @click="widgetOnConfig" />
        <IconButton
          icon="ndl-icon-more-vertical"
          @click="widgetOnContextmenu"
        />
      </div>
    </div>
    <div ref="widgetBody" class="widget-body">
      <slot></slot>
    </div>
    <span
      v-if="resizableAndNotStatic"
      ref="handle"
      :class="resizableHandleClass"
    ></span>
  </div>
</template>

<script>
import { GridItem } from "vue-grid-layout";
import IconButton from "@/views/datamodel/components/icon-button";
import * as helper from "./helper";

export default {
  inheritAttrs: false,
  // 此组件 mix-in <GridItem /> 组件，为避免属性/方法与原组件冲突
  // 新增的 props / methods 统一添加前缀 widget
  mixins: [GridItem],
  components: { IconButton },
  props: {
    /** @type {import("../models/widget").default} */
    widget: Object,
    widgetReadonly: Boolean
  },
  mounted() {
    this.widgetInitObserver();
  },
  destroyed() {
    this.widgetDestroyObserver();
  },
  methods: {
    widgetInitObserver() {
      if (!this.widget.autosize) {
        return this.widgetDestroyObserver();
      }
      const observer = helper.observe(this.$refs.widgetBody);
      observer.onresize(() => {
        if (!this.isResizing) {
          this.autoSize();
        }
      });
      this._widgetObserver = observer;
    },
    widgetDestroyObserver() {
      if (this._widgetObserver) {
        this._widgetObserver.disconnect();
        delete this._widgetObserver;
      }
    },
    widgetOnConfig() {
      this.$parent.$emit("config", this.widget);
    },
    async widgetOnContextmenu(evt) {
      const option = await helper.showWidgetOptions(
        this.widget,
        evt.target,
        this.$el
      );
      this.$parent.$emit(option.key, this.widget);
    }
  }
};
</script>

<style lang="less" scoped>
@import "../vars.less";

.vue-grid-item {
  @box-shadow: @widget-box-shadow;
  @background: @widget-background;
  overflow: hidden;
  border-radius: @widget-border-radius;
  box-shadow: @box-shadow;
  background: @background;
  display: flex;
  flex-direction: column;
  // ⚠️ width 和 height 不能 tranition，否则会导致以来宽高的计算逻辑异常
  transition-property: left, top, right, box-shadow, background-color;
  // auto-size 的组件默认不显示阴影效果，扁平化 UI
  &.autosize {
    box-shadow: none;
    background: none;
  }
  &.autosize:not(.readonly):hover {
    box-shadow: @box-shadow;
    background: @background;
  }
  &:hover .widget-actions {
    visibility: visible;
  }
}
.widget-header {
  height: @widget-header-height;
  display: flex;
  align-items: center;
  padding: 0 8px;
  cursor: grab;
  &.flexible {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: inherit;
    transform: translateY(-100%);
    transition: transform 0.3s;
  }
  &.flexible.readonly {
    display: none;
  }
  .ndl-icon-move-drag {
    color: rgba(0, 0, 0, 0.45);
  }
  .widget-title {
    flex: 1 1 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .widget-actions {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    visibility: hidden;
  }
}
.vue-grid-item:hover .widget-header.flexible {
  transform: translateY(0);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}
.vue-grid-item.vue-draggable-dragging .widget-header {
  cursor: grabbing;
}
.widget-body {
  flex: 1 1 0;
  overflow: auto;
}
</style>
