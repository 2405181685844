import { random as uuid } from "@/utils/string";
import { IWidgetDatasource } from "../widgets/common.interface";
import * as Widgets from "../widgets";

export default class Widget {
  // widget 位置信息，用于 <GridItem /> 组件。组件默认尺寸为 12 * 20。
  w = 12;
  h = 20;
  x = 0;
  y = 0;
  i = uuid(12);
  // widget 类型信息
  type = "";
  title = "";
  options = {};
  datasource = {};
  version = 0;
  constructor(opts) {
    opts = opts || {};
    const WidgetDescriber = Widgets.getWidget(opts.type);
    // 初始化组件默认的 title、宽高
    if (WidgetDescriber) {
      this.title = WidgetDescriber.name;
      Object.assign(this, WidgetDescriber.size);
    }
    Object.assign(this, opts);
    this.datasource = IWidgetDatasource.from(opts.datasource);
    this.options = Object.assign({}, opts.options);
  }
  get id() {
    return this.i;
  }
  // 是否根据自动调整组件高度
  get autosize() {
    return Widgets.isWidgetAutosize(this.type);
  }
  // 是否需要隐藏 widget header
  get flexible() {
    return Widgets.isWidgetFlexible(this.type);
  }
  /**
   * @param {Widget} opts
   * @returns {Widget}
   */
  static from(opts) {
    return new Widget(opts);
  }
  static for(x = 0, y = 0, w = 1, h = 1, type) {
    return new Widget({ x, y, w, h, type });
  }
}
