export const type = "pie-basic";

export const name = "饼图";

export const description = "常用于数据对比";

export const icon = `<svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M483.556 540.444V145.067C292.978 159.289 142.222 318.577 142.222 512c0 204.8 164.978 369.778 369.778 369.778 193.422 0 352.711-150.756 366.933-341.334H483.556z" fill="#706EE7" p-id="8639"></path><path d="M540.444 116.622v366.934h366.934C896 287.289 736.71 128 540.444 116.622z" fill="#29C287"></path></svg>`;

export { GroupPie as group } from "../groups";

export const dimensions = "row: 1, value: 1, group: 0";

// eslint-disable-next-line prettier/prettier
export const Playground = () => import(/* webpackChunkName: "widget-playground" */ "./Playground");

// eslint-disable-next-line prettier/prettier
export const Decoration = () => import(/* webpackChunkName: "widget-decoration" */ "./Decoration")

// eslint-disable-next-line prettier/prettier
export const Datasource = () => import(/* webpackChunkName: "widget-decoration" */ "./Datasource")
