import Axios from "axios";
import { interceptor } from "./index";
import resolveURL from "@/utils/baseurl";

const axios = Axios.create({ baseURL: resolveURL("/api/shared") });

// 分享页面的接口也可能会需要登陆访问，例如看板分享
interceptor.auth(axios);

// 分享出去的接口统一移除 x-space-id 头，避免接口报错
axios.interceptors.request.use(config => {
  delete config.headers.common["x-space-id"];
  return config;
});

// 获取看板分享后的 layout
export function getDashboardLayout(sharedId) {
  const params = { sharedId };
  return axios.get("dashboard/detail", { params });
}

export function getWidgetData(data) {
  return axios.post("dashboard/chart/data", data);
}
