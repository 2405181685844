export const type = "mixed-linebar";

export const name = "折线-柱状图";

export const description = "常用于展示数据变化趋势";

export const icon = `<svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M836.36703 796.383677H971.170424V11.636364H836.36703v784.747313z m-240.716283 0H730.452848V363.085576H595.650747v433.298101z m-240.722747 0h134.803394v-544.025859H354.928v544.025859z m-240.717576 0H249.013818V512.333576H114.210424v284.050101z m0 0" fill="#706EE7" p-id="25971"></path><path d="M932.655354 131.993859c4.813576 0 9.63103 4.818747 14.444606 4.818747 14.444606 9.625859 19.258182 28.88404 9.625858 43.328647L942.286384 194.585859c-62.590707 28.882747-129.989818 48.140929-192.575354 72.211394-28.889212 14.444606-48.146101 33.702788-62.590707 57.773252-24.070465 48.146101-48.140929 101.105778-72.212687 154.060283-9.63103 19.258182-24.075636 38.516364-38.51507 52.959677-43.332525 38.515071-101.105778 57.773253-158.879031 52.959676-19.256889 0-43.327354-9.63103-62.585535-14.444606-9.625859-4.813576-19.256889-9.625859-33.701495-14.444606-62.586828-28.88404-134.803394 0-168.499717 62.590707-14.444606 24.070465-24.076929 52.954505-33.702788 77.030142 0 4.813576-4.813576 9.625859-9.63103 14.439434-9.625859 4.818747-19.258182 9.63103-28.884041 4.818748-9.63103 0-19.256889-9.63103-24.070464-19.258182-4.818747-9.63103-4.818747-19.258182 0-24.075637 9.625859-24.070465 24.070465-52.954505 33.696323-77.030141 9.632323-19.258182 24.075636-38.515071 38.515071-52.954505 43.333818-43.333818 96.288323-62.592 158.87903-57.773253 24.070465 0 43.328646 9.625859 67.399111 19.256889 9.63103 4.813576 19.258182 9.627152 33.702788 14.439435 14.443313 4.818747 28.882747 9.63103 43.327353 9.63103 38.515071 4.813576 72.217859-9.63103 101.101899-33.701495 9.63103-9.625859 19.256889-24.070465 28.88792-38.515071 24.070465-48.142222 48.140929-101.100606 72.216565-154.060283 14.439434-28.88404 38.515071-57.773253 67.399111-77.031434 9.63103-4.812283 24.070465-14.443313 33.701495-19.256889l187.763071-72.217858h9.627152" fill="#29C287" p-id="25972"></path></svg>`;

export { GroupMixed as group } from "../groups";

export const dimensions = "row: 1, value.line: 1+, value.bar: 1+, group: 0-1";

// eslint-disable-next-line prettier/prettier
export const Playground = () => import(/* webpackChunkName: "widget-playground" */ "./Playground");

// eslint-disable-next-line prettier/prettier
export const Decoration = () => import(/* webpackChunkName: "widget-decoration" */ "./Decoration")

// eslint-disable-next-line prettier/prettier
export const Datasource = () => import(/* webpackChunkName: "widget-decoration" */ "./Datasource")
