export const type = "pie-ring";

export const name = "环形图";

export const description = "常用于数据对比";

export const icon = `<svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M546.747475 259.665455c145.454545 18.182465 219.636364 124.36299 219.636363 253.090909 0 33.454545-5.091556 65.454545-16.727919 94.545454l126.545455 73.453899c23.272727-50.907798 34.909091-108.36299 34.909091-167.999353 0-209.454545-146.182465-381.818828-364.364283-400v146.909091z m0 0" fill="#706EE7" p-id="25426"></path><path d="M474.020202 765.847273c-109.090909-18.182465-217.453899-124.364283-217.453899-253.090909 0-128.727919 108.36299-234.908444 217.453899-253.090909V112.756364C292.20202 130.937535 111.838384 303.301818 111.838384 512.756364s180.363636 381.818828 362.181818 400V765.847273z m0 0" fill="#29C287" p-id="25427"></path><path d="M712.565657 670.573899c-40.000646 50.907798-93.090909 85.091556-165.818182 95.273374V912.756364c109.090909-10.909737 224.727919-74.909737 290.182464-168.727273l-124.364282-73.455192z m0 0" fill="#FFC107"></path></svg>`;

export { GroupPie as group } from "../groups";

export { dimensions } from "../pie-basic";

// eslint-disable-next-line prettier/prettier
export const Playground = () => import(/* webpackChunkName: "widget-playground" */ "./Playground");

// eslint-disable-next-line prettier/prettier
export const Decoration = () => import(/* webpackChunkName: "widget-decoration" */ "../pie-basic/Decoration")

// eslint-disable-next-line prettier/prettier
export const Datasource = () => import(/* webpackChunkName: "widget-decoration" */ "../pie-basic/Datasource")
