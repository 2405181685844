export const type = "bar-stack";

export const name = "堆叠条形图";

export const description = "常用于数据对比";

export const icon = `<svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M496.398222 129.292929l0 147.105616-323.635717 0L172.762505 129.292929z" fill="#29C287" p-id="9180"></path><path d="M894.249374 335.614707l0 147.106909-676.692041 1e-8L217.557333 335.614707z" fill="#706EE7" p-id="9181"></path><path d="M614.085818 541.191758l0 147.105616-441.323313 0L172.762505 541.191758z" fill="#29C287" p-id="9182"></path><path d="M732.661657 129.292929l0 147.104324-235.37389899 0L497.287758 129.292929z" fill="#706EE7" p-id="9183"></path><path d="M614.575838 335.616l0 147.10561601L173.252525 482.721616 173.252525 335.616z" fill="#29C287" p-id="9184"></path><path d="M682.640808 540.902141L682.640808 688.006465l-235.373899 0L447.266909 540.902141z" fill="#706EE7" p-id="9185"></path><path d="M612.822626 745.015596L612.822626 892.121212l-441.323313 0L171.499313 745.015596z" fill="#29C287" p-id="9186"></path><path d="M692.984242 745.015596l1e-8 147.104323-235.37389901 0L457.610343 745.01559599z" fill="#706EE7" p-id="9187"></path></svg>`;

export { GroupBar as group } from "../groups";

export { dimensions } from "../bar-basic";

// eslint-disable-next-line prettier/prettier
export const Playground = () => import(/* webpackChunkName: "widget-playground" */ "./Playground");

// eslint-disable-next-line prettier/prettier
export const Decoration = () => import(/* webpackChunkName: "widget-decoration" */ "../bar-basic/Decoration")

// eslint-disable-next-line prettier/prettier
export const Datasource = () => import(/* webpackChunkName: "widget-decoration" */ "../bar-basic/Datasource")
