var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"item",class:[
    'vue-grid-item',
    _vm.classObj,
    { autosize: _vm.widget.autosize, readonly: _vm.widgetReadonly }
  ],style:(_vm.style)},[_c('div',{class:[
      'widget-header',
      { flexible: _vm.widget.flexible, readonly: _vm.widgetReadonly }
    ]},[_c('div',{staticClass:"widget-title"},[_c('i',{staticClass:"ndl-icon-move-drag"}),_vm._v(" "+_vm._s(_vm.widget.title || "未命名组件")+" ")]),(!_vm.widgetReadonly)?_c('div',{staticClass:"widget-actions"},[_c('IconButton',{attrs:{"icon":"ndl-icon-settings"},on:{"click":_vm.widgetOnConfig}}),_c('IconButton',{attrs:{"icon":"ndl-icon-more-vertical"},on:{"click":_vm.widgetOnContextmenu}})],1):_vm._e()]),_c('div',{ref:"widgetBody",staticClass:"widget-body"},[_vm._t("default")],2),(_vm.resizableAndNotStatic)?_c('span',{ref:"handle",class:_vm.resizableHandleClass}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }