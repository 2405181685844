export const type = "kpi";

export const name = "单值指标卡";

export const description = "常用于展示统计值";

export const icon = `<svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M142.222 796.444h142.222l-71.11-142.222z m199.111-85.333h568.89v85.333h-568.89z" fill="#29C287" p-id="11839"></path><path d="M876.089 307.2c0-54.044-45.511-99.556-99.556-99.556s-99.555 45.512-99.555 99.556h56.889c0-22.756 19.91-42.667 42.666-42.667S819.2 284.444 819.2 307.2c0 17.067-8.533 31.289-22.756 36.978-8.533 5.689-17.066 14.222-17.066 25.6s5.689 19.91 17.066 25.6c14.223 8.533 22.756 22.755 22.756 36.978 0 22.755-19.911 42.666-42.667 42.666s-42.666-19.91-42.666-42.666h-56.89c0 54.044 45.512 99.555 99.556 99.555s99.556-45.511 99.556-99.555c0-22.756-8.533-45.512-22.756-62.578 14.223-17.067 22.756-39.822 22.756-62.578zM256 207.644c-8.533-2.844-19.911-2.844-28.444 5.69l-108.09 85.333 34.134 42.666 59.733-48.355V531.91h56.89V236.09c2.844-11.378-2.845-22.756-14.223-28.445zM591.644 307.2c0-25.6-11.377-51.2-28.444-71.111-19.911-19.911-42.667-28.445-71.111-28.445-25.6 0-51.2 11.378-71.111 28.445s-28.445 42.667-28.445 71.111h56.89c0-11.378 5.688-22.756 11.377-31.289 17.067-17.067 42.667-17.067 59.733 0 8.534 8.533 11.378 19.911 11.378 31.289s-5.689 22.756-14.222 31.289L398.222 486.4c-5.689 8.533-8.533 19.911-2.844 31.289s14.222 17.067 25.6 17.067h170.666v-56.89H480.711l82.489-99.555c17.067-19.911 28.444-45.511 28.444-71.111z" fill="#706EE7" p-id="11840"></path></svg>`;

export { GroupKpi as group } from "../groups";

export const dimensions = "row: 0, value: 1, group: 0";

// 组件默认尺寸
export const size = { w: 6, h: 8 };

// 自动收起组件 header
export const flexible = true;

// eslint-disable-next-line prettier/prettier
export const Playground = () => import(/* webpackChunkName: "widget-playground" */ "./Playground");

// eslint-disable-next-line prettier/prettier
export const Decoration = () => import(/* webpackChunkName: "widget-decoration" */ "./Decoration")

// eslint-disable-next-line prettier/prettier
export const Datasource = () => import(/* webpackChunkName: "widget-decoration" */ "./Datasource")
