export const type = "table-basic";

export const name = "表格";

export const description = "常用于展示数据";

export const icon = `<svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1452"><path d="M128 412.444h768v85.334H128z m0 199.112h768v85.333H128z" fill="#29C287" p-id="1453"></path><path d="M113.778 184.889V839.11h796.444V184.89H113.778z m28.444 625.778v-512h227.556v512H142.222z m256 0v-512h256v512h-256z m483.556 0H682.667v-512h199.11v512z" fill="#706EE7" p-id="1454"></path></svg>`;

export { GroupTable as group } from "../groups";

export const dimensions = "row: 0, value: 1+, group: 0";

// 组件默认尺寸
export const size = { w: 6, h: 8 };

// 自动收起组件 header
// export const flexible = true;

// eslint-disable-next-line prettier/prettier
export const Playground = () => import(/* webpackChunkName: "widget-playground" */ "./Playground");

// eslint-disable-next-line prettier/prettier
export const Decoration = () => import(/* webpackChunkName: "widget-decoration" */ "./Decoration")

// eslint-disable-next-line prettier/prettier
export const Datasource = () => import(/* webpackChunkName: "widget-decoration" */ "./Datasource")
