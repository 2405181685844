<template>
  <span class="icon-btn" v-on="$listeners">
    <slot>
      <i :class="icon"></i>
    </slot>
  </span>
</template>

<script>
export default {
  props: {
    icon: String
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

.icon-btn {
  @size-md: 26px;
  width: @size-md;
  height: @size-md;
  font-size: 16px;
  font-weight: bold;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  cursor: pointer;
  color: @text-color-secondary;
  &:hover {
    background: @blue-1;
    color: @blue-6;
  }
  & + & {
    margin-left: 4px;
  }
}
</style>
