export const type = "histogram-stack";

export const name = "堆叠柱状图";

export const description = "常用于数据对比";

export const icon = `<svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M129.292929 527.601778h147.105616v323.635717H129.292929z" fill="#29C287" p-id="9007"></path><path d="M335.614707 129.750626h147.106909v676.692041H335.614707z" fill="#706EE7" p-id="9008"></path><path d="M541.191758 409.914182h147.105616v441.323313H541.191758z" fill="#29C287" p-id="9009"></path><path d="M129.292929 291.338343h147.104324v235.373899H129.292929z" fill="#706EE7" p-id="9010"></path><path d="M335.616 409.424162h147.105616V850.747475H335.616z" fill="#29C287" p-id="9011"></path><path d="M540.902141 341.359192H688.006465v235.373899H540.902141z" fill="#706EE7" p-id="9012"></path><path d="M745.015596 411.177374H892.121212v441.323313H745.015596z" fill="#29C287" p-id="9013"></path><path d="M745.015596 331.015758h147.104323v235.373899H745.015596z" fill="#706EE7" p-id="9014"></path></svg>`;

export { GroupHistogram as group } from "../groups";

export { dimensions } from "../bar-basic";

// eslint-disable-next-line prettier/prettier
export const Playground = () => import(/* webpackChunkName: "widget-playground" */ "./Playground");

// eslint-disable-next-line prettier/prettier
export const Decoration = () => import(/* webpackChunkName: "widget-decoration" */ "../bar-basic/Decoration")

// eslint-disable-next-line prettier/prettier
export const Datasource = () => import(/* webpackChunkName: "widget-decoration" */ "../histogram-basic/Datasource")
