import debounce from "lodash/debounce";
import Observer from "@/utils/observer";
import showContextmenu, { ContextOption } from "@/components/contextmenu";
import * as Widgets from "../widgets";

export function showWidgetOptions(widget, source, container) {
  const options = [
    new ContextOption("config", "配置组件", "settings"),
    widget.type == Widgets.Text.type
      ? null
      : new ContextOption(
          "redirect",
          "跳转至关联视图",
          "share-forward-box-line"
        ),
    new ContextOption("delete", "移除组件", "trash-2")
      .$iconClass("ndl-color-red")
      .$labelClass("ndl-color-red")
  ].filter(Boolean);
  return showContextmenu({ options }, { source, container });
}

/**
 * 监听 widgetBody slot element 及其尺寸变化
 * @param {Element} widgetBody <GridItem /> 中的 .widget-body
 */
export function observe(widgetBody) {
  const EVENT_NAME = "resize";
  const emitter = new Observer();

  const resizeObserver = new ResizeObserver(
    debounce(() => emitter.emit(EVENT_NAME), 200)
  );

  const mutationObserver = new MutationObserver(() => {
    ob.observe(widgetBody.firstElementChild);
  });
  mutationObserver.observe(widgetBody, { childList: true, subtree: true });

  let slotElement = null;
  const ob = {
    getWidgetBody() {
      return widgetBody;
    },
    getSlot() {
      return slotElement;
    },
    onresize(fn) {
      emitter.on(EVENT_NAME, fn);
    },
    disconnect() {
      emitter.off(EVENT_NAME);
      mutationObserver.disconnect();
      resizeObserver.disconnect();
    },
    observe(elm) {
      // 先取消旧 slot element 的监听器
      if (slotElement) {
        resizeObserver.unobserve(slotElement);
      }
      // 再更新当前 slotElement，指向最新的子元素
      slotElement = elm;
      // 最后给最新的 slot element 添加监听器
      if (slotElement) {
        resizeObserver.observe(slotElement);
      }
    }
  };
  ob.observe(widgetBody.firstElementChild);
  return ob;
}
