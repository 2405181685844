export const type = "line-basic";

export const name = "基础折线图";

export const description = "常用于展示数据变化趋势";

export const icon = `<svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M497.778 790.756L304.356 651.378l-133.69 108.089-54.044-68.267 182.045-147.911 204.8 145.067 358.4-213.334 45.51 73.956z" fill="#29B983"></path><path d="M497.778 534.756L304.356 395.378l-133.69 108.089-54.044-68.267 182.045-147.911 204.8 145.067 358.4-213.334 45.51 73.956z" fill="#706EE7"></path></svg>`;

export { GroupLine as group } from "../groups";

export { dimensions } from "../bar-basic";

// eslint-disable-next-line prettier/prettier
export const Playground = () => import(/* webpackChunkName: "widget-playground" */ "./Playground");

// eslint-disable-next-line prettier/prettier
export const Decoration = () => import(/* webpackChunkName: "widget-decoration" */ "./Decoration")

// eslint-disable-next-line prettier/prettier
export const Datasource = () => import(/* webpackChunkName: "widget-decoration" */ "../histogram-basic/Datasource")
